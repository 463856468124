.hero {
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Dark transparent overlay */
    z-index: 1;
}

.hero-content {
    position: relative;
    z-index: 2; /* Display above overlay */
    color: white;
    text-align: center;
}

.hero-content h1 {
    font-size: 36px;
    margin-bottom: 10px;
}

.hero-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.search-bar {
    display: flex;
    margin-bottom: 20px;
}

.search-bar input {
    padding: 10px;
    width: 300px;
    border: none;
    border-radius: 5px 0 0 5px;
}

.search-bar button {
    padding: 10px;
    border: none;
    background-color: #4d23e8;
    color: white;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
}

.certification img {
    max-height: 80px;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .hero-content h1 {
        font-size: 28px;
    }

    .hero-content h2 {
        font-size: 18px;
    }

    .search-bar input {
        width: 200px;
    }
}
